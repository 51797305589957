<template>
  <div class="list-container">
    <table-container :list="list" height="750">
      <el-table-column type="index" label="序号" width="50" fixed>
      </el-table-column>
      <el-table-column prop="id" label="ID" fixed> </el-table-column>
      <el-table-column prop="batch_no" label="批次" fixed> </el-table-column>
      <el-table-column prop="ordersn" label="订单号" fixed> </el-table-column>
      <el-table-column prop="stuff" label="材质"> </el-table-column>
      <el-table-column prop="brandType" label="品牌型号"> </el-table-column>

      <el-table-column prop="img" label="图片"> </el-table-column>
      <el-table-column prop="count" label="数量"></el-table-column>
      <el-table-column prop="agent_name" label="所属代理"></el-table-column>
      <el-table-column prop="expressNo" label="快递单号"> </el-table-column>
      <el-table-column prop="commit_admin" label="提交人"> </el-table-column>
      <el-table-column prop="done_admin" label="处理人"> </el-table-column>
      <el-table-column prop="problem_type" label="问题类型" fixed="right">
      </el-table-column>
      <el-table-column prop="problem_status" label="问题状态" fixed="right">
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" fixed="right">
      </el-table-column>
      <el-table-column prop="updated_at" label="处理时间" fixed="right">
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleAgain(scope.row.id)"
            >重打</el-button
          >
        </template>
      </el-table-column>
    </table-container>
  </div>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    //重打
    async handleAgain(problem_ids) {
      const res = await this.$store.dispatch("againOrder", {
        problem_ids: JSON.stringify([problem_ids]),
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.list-container {
  width: 100%;
}
</style>