<template>
  <div class="problem-list">
    <prod-nav @change="handleChange" />
    <list :list="list" @succ="getProblemList"></list>
    <el-pagination
      background
      layout="prev, pager, next,sizes"
      :page-size="pageSize"
      :page-sizes="pageSizes"
      :total="total"
      :hide-on-single-page="true"
      @size-change="sizeChange"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>
<script>
import ProdNav from "./components/Nav";
import List from "./components/List";
export default {
  name: "ProblemList",
  components: { ProdNav, List },
  data() {
    return {
      page: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      total: 100,
      list: [],
      startTime: "",
      endTime: "",
      problem_type: [],
      deliver_type: "",
    };
  },
  created() {
    // this.getProblemList();
  },
  methods: {
    async getProblemList() {
      const res = await this.$store.dispatch("getProblemList", {
        page: this.page,
        pageSize: this.pageSize,
        startTime: this.startTime,
        endTime: this.endTime,
        problem_type: JSON.stringify(this.problem_type),
        deliver_type: this.deliver_type,
      });
      this.list = res.items.map((item) => {
        const {
          id,
          batch_no,
          ordersn,
          problem_status,
          problem_type,
          remark,
          status,
          created_at,
          updated_at,
          attrs,
        } = item;

        const stuff = attrs.find((item) => item.name === "材质").value;
        const brandType = attrs.find((item) => item.name === "品牌型号").value;
        const agent_name = attrs.find((item) => item.name === "所属代理").value;
        const expressNo = attrs.find((item) => item.name === "快递单号").value;
        const commit_admin = attrs.find((item) => item.name === "提交人").value;
        const done_admin = attrs.find((item) => item.name === "处理人").value;
        const count = attrs.find((item) => item.name === "数量").value;
        return {
          id,
          batch_no,
          ordersn,
          stuff,
          brandType,
          agent_name,
          expressNo,
          commit_admin,
          done_admin,
          count,
          problem_status,
          problem_type,
          remark,
          status,
          created_at,
          updated_at,
        };
      });
      this.total = res.total;
    },
    //页面展示数量改变
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getProblemList();
    },
    //产品页页码改变事件
    changePage(page) {
      this.page = page;
      this.getProblemList();
    },
    //筛选改变
    handleChange(param) {
      const { startTime, endTime, deliver_type, problemType } = param;
      this.startTime = startTime;
      this.endTime = endTime;
      this.deliver_type = deliver_type;
      this.problemType = problemType;
      this.getProblemList();
    },
  },
};
</script>
<style lang="less" scoped>
.problem-list {
  width: 100%;
}
</style>