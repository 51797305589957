<template>
  <el-row :gutter="20">
    <el-col :span="2">
      <el-select v-model="deliver_type" placeholder="请选择">
        <el-option
          v-for="item in deliver_types"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="4">
      <all-select
        :model="problemType"
        :all="problemTypes"
        placeholder="问题类型选择"
        @change="handleCheckedProblemType"
      >
        <el-option
          v-for="item in problemTypeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </all-select>
      <!-- <el-select v-model="problemType" placeholder="请选择">
        <el-option
          v-for="item in problemTypeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select> -->
    </el-col>
    <el-col :span="7">
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </el-col>
    <el-col :span="3">
      <el-input v-model="searchText" placeholder="请输入订单号"></el-input>
    </el-col>
    <el-col :span="3">
      <el-button type="primary" icon="el-icon-search" @click="handleSearchClick"
        >搜索</el-button
      >
    </el-col>
  </el-row>
</template>
<script>
import { dateShortcuts, toDaytime } from "@/util/shortcuts";
import AllSelect from "@/components/AllSelect";
export default {
  name: "Nav",
  components: { AllSelect },
  data() {
    return {
      deliver_type: "0",
      deliver_types: [
        {
          value: "-1",
          label: "全部",
        },
        {
          value: "0",
          label: "未发货",
        },
        {
          value: "1",
          label: "已发货",
        },
      ],
      date: "",
      startTime: "",
      endTime: "",
      pickerOptions: {
        shortcuts: dateShortcuts,
      },
      searchText: "",
      problemTypeList: [],
      problemType: [],
      problemTypes: [],
    };
  },
  watch: {
    date(e) {
      if (e) {
        this.startTime = Math.floor(e[0] / 1000);
        this.endTime = Math.floor(e[1] / 1000);
        this.handleChange();
      }
    },
    deliver_type() {
      this.handleChange();
    },
  },
  created() {
    this.handleChange();
    this.getProblemType();
  },
  methods: {
    async getProblemType() {
      //获取问题类型列表
      let data = await this.$api.flowApi.problemTypes();
      if (data.code != 0) {
        this.$message({
          message: "问题件类型获取失败",
          type: "error",
        });
        return;
      }
      this.problemTypeList = data.items;
      this.problemTypes = this.problemTypeList.map((item) => {
        return item.id;
      });
    },
    //选择问题类型
    handleCheckedProblemType(e) {
      this.problemType = e;
      this.handleChange();
    },
    handleSearchClick() {
      this.$emit("search", this.searchText);
    },
    //选择时间
    handleChange() {
      this.$emit("change", {
        deliver_type: this.deliver_type,
        problemType: this.problemType,
        startTime: this.startTime,
        endTime: this.endTime,
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>